// =======================================
/* Keeping all the action types in one single file as constants is suggested by redux
Read more about action types as constants here: http://redux.js.org/docs/recipes/ReducingBoilerplate.html#actions */
// ========================================

// Async actions for apis like, FETCH_ORGANIZATIONS, FETCH_USER dispaches the 3 more actions by redux-promise middleware
/* Example FETCH_ORGANIZATIONS dispaches FETCH_ORGANIZATIONS_PENDING,
FETCH_ORGANIZATIONS_FULFILLED, FETCH_ORGANIZATIONS_REJECTED. Read more about redux-promise-middleware
here: https://github.com/pburtchaell/redux-promise-middleware/blob/master/docs/introduction.md */

/* COMMON
========================================= */
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'

export const LOCATION_CHANGE = 'LOCATION_CHANGE'

export const FETCH_USER = 'FETCH_USER'

export const REMOVE_ERROR = 'REMOVE_ERROR'

export const SHOW_LOADER = 'SHOW_LOADER'

/* ORGANIZATION LIST PAGE
========================================= */
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS'

export const FETCH_ORGANIZATIONS_FULFILLED = 'FETCH_ORGANIZATIONS_FULFILLED'

export const FETCH_ORGANIZATIONS_CSV = 'FETCH_ORGANIZATIONS_CSV'

export const RESEND_ORGANIZATION_VERIFICATION = 'RESEND_ORGANIZATION_VERIFICATION'

export const RESEND_ORGANIZATION_VERIFICATION_FULFILLED = 'RESEND_ORGANIZATION_VERIFICATION_FULFILLED'

export const GET_ORGANIZATION = 'GET_ORGANIZATION'

export const GET_ORGANIZATION_FULFILLED = 'GET_ORGANIZATION_FULFILLED'

/* ORGANIZATION INFO PAGE
========================================= */

export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION'

export const FETCH_ORGANIZATION_DETAILS_CSV = 'FETCH_ORGANIZATION_DETAILS_CSV'

export const FETCH_ORGANIZATION_FULFILLED = 'FETCH_ORGANIZATION_FULFILLED'

export const PUT_ORGANIZATION = 'PUT_ORGANIZATION'

export const PUT_ORGANIZATION_FULFILLED = 'PUT_ORGANIZATION_FULFILLED'

export const POST_MEMBERS = 'POST_MEMBERS'

export const POST_MEMBERS_FULFILLED = 'POST_MEMBERS_FULFILLED'

export const UPDATE_MEMBER_NAME_IN_LIST = 'UPDATE_MEMBER_NAME_IN_LIST'

export const SELECT_MEMBERS = 'SELECT_MEMBERS'

export const UNSELECT_MEMBERS = 'UNSELECT_MEMBERS'

export const CHECK_FOR_EXISTING_USER = 'CHECK_FOR_EXISTING_USER'

export const POST_MAKE_OWNER = 'POST_MAKE_OWNER'

export const POST_MAKE_OWNER_FULFILLED = 'POST_MAKE_OWNER_FULFILLED'

export const POST_INVITE_AND_MAKE_OWNER = 'POST_INVITE_AND_MAKE_OWNER'

export const POST_INVITE_AND_MAKE_OWNER_FULFILLED = 'POST_INVITE_AND_MAKE_OWNER_FULFILLED'

export const DELETE_USER = 'DELETE_USER'

export const DELETE_MEMBERS = 'DELETE_MEMBERS'

export const REMOVE_MEMBERS_FROM_LIST = 'REMOVE_MEMBERS_FROM_LIST'

export const PATCH_USER = 'PATCH_USER'

export const PATCH_RESEND_INVITE = 'PATCH_RESEND_INVITE'

export const DELETE_INVITATION = 'DELETE_INVITATION'

export const REMOVE_INVITATION_FROM_LIST = 'REMOVE_INVITATION_FROM_LIST'

export const POST_MEMBERS_CSV_UPLOAD = 'POST_MEMBERS_CSV_UPLOAD'

export const POST_MEMBERS_CSV_UPLOAD_FULFILLED = 'POST_MEMBERS_CSV_UPLOAD_FULFILLED'

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'

/* TAGS PAGE
========================================= */
export const FETCH_ORGANIZATION_TAGS = 'FETCH_ORGANIZATION_TAGS'

export const FETCH_TAGS_CSV = 'FETCH_TAGS_CSV'

export const POST_TAGS_CSV_UPLOAD = 'POST_TAGS_CSV_UPLOAD'

export const SELECT_TAGS = 'SELECT_TAGS'

export const ASSIGN_TAG_AND_ASSOCIATE_QR_CODE = 'ASSIGN_TAG_AND_ASSOCIATE_QR_CODE'

export const GET_ASSIGN_TAG_FIX_ME_CSV = 'GET_ASSIGN_TAG_FIX_ME_CSV'

export const ASSIGN_TAG_AND_ASSOCIATE_QR_CODE_FULFILLED = 'ASSIGN_TAG_AND_ASSOCIATE_QR_CODE_FULFILLED'

export const FETCH_ORGANIZATION_TAGS_FULFILLED = 'FETCH_ORGANIZATION_TAGS_FULFILLED'

export const DELETE_ASSIGNED_TAG = 'DELETE_ASSIGNED_TAG'

export const CHANGE_QR_CODE = 'CHANGE_QR_CODE'

export const DE_ASSOCIATE_QR_CODE = 'DE_ASSOCIATE_QR_CODE'

export const PAIR_QR_CODE = 'PAIR_QR_CODE'

export const UPDATE_TAG_ASSOCIATION = 'UPDATE_TAG_ASSOCIATION'

/* AGGREGATION UNITS PAGE
========================================= */
export const FETCH_AGGREGATION_UNITS = 'FETCH_AGGREGATION_UNITS'

export const FETCH_AGGREGATION_UNIT = 'FETCH_AGGREGATION_UNIT'

export const FETCH_AGGREGATION_UNIT_FULFILLED = 'FETCH_AGGREGATION_UNIT_FULFILLED'

export const POST_AGGREGATION_UNIT_IMAGES = 'POST_AGGREGATION_UNIT_IMAGES'

export const FETCH_AGGREGATION_UNITS_CSV = 'FETCH_AGGREGATION_UNITS_CSV'

export const FETCH_AGGREGATION_UNITS_FULFILLED = 'FETCH_AGGREGATION_UNITS_FULFILLED'

export const SELECT_AGGREGATION_UNITS = 'SELECT_AGGREGATION_UNITS'

export const UNSELECT_AGGREGATION_UNITS = 'UNSELECT_AGGREGATION_UNITS'

export const POST_AGGREGATION_UNITS = 'POST_AGGREGATION_UNITS'

export const POST_AGGREGATION_UNITS_FULFILLED = 'POST_AGGREGATION_UNITS_FULFILLED'

export const PUT_AGGREGATION_UNIT = 'PUT_AGGREGATION_UNIT'

export const PUT_AGGREGATION_UNIT_FULFILLED = 'PUT_AGGREGATION_UNIT_FULFILLED'

export const DELETE_AGGREGATION_UNIT = 'DELETE_AGGREGATION_UNIT'

export const DELETE_AGGREGATION_UNIT_FROM_LIST = 'DELETE_AGGREGATION_UNIT_FROM_LIST'

/* REPORTS PAGE
========================================= */
export const FETCH_REPORTS = 'FETCH_REPORTS'

export const FETCH_REPORTS_CSV = 'FETCH_REPORTS_CSV'

export const FETCH_REPORTS_FULFILLED = 'FETCH_REPORTS_FULFILLED'

export const PUT_REPORT = 'PUT_REPORT'

export const PUT_REPORT_FULFILLED = 'PUT_REPORT_FULFILLED'

export const REMOVE_EMAIL_FROM_SUBSCRIPTION_LIST = 'REMOVE_EMAIL_FROM_SUBSCRIPTION_LIST'

export const POST_SUBSCRIBERS = 'POST_SUBSCRIBERS'

export const ON_ADD_SUBSCRIBERS = 'ON_ADD_SUBSCRIBERS'

/* REQUESTS PAGE
========================================= */
export const FETCH_REQUESTS = 'FETCH_REQUESTS'

export const FETCH_REQUESTS_FULFILLED = 'FETCH_REQUESTS_FULFILLED'

export const FETCH_REQUESTS_VALIDATION = 'FETCH_REQUESTS_VALIDATION'

export const FETCH_REQUESTS_VALIDATION_FULFILLED = 'FETCH_REQUESTS_VALIDATION_FULFILLED'

export const POST_REQUESTS = 'POST_REQUESTS'

export const POST_REQUESTS_FULFILLED = 'POST_REQUESTS_FULFILLED'

export const PUT_REQUESTS_STATE = 'PUT_REQUESTS_STATE'

export const PUT_REQUESTS_STATE_FULFILLED = 'PUT_REQUESTS_STATE_FULFILLED'

export const PUT_REQUEST = 'PUT_REQUEST'

export const PUT_REQUEST_FULFILLED = 'PUT_REQUEST_FULFILLED'

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'

export const CREATE_ORGANIZATION_FULFILLED = 'CREATE_ORGANIZATION_FULFILLED'

export const INVITE_OWNER = 'INVITE_OWNER'

export const INVITE_OWNER_FULFILLED = 'INVITE_OWNER_FULFILLED'
