import React from 'react'
import { Link } from 'react-router'

class AppbarLogo extends React.Component {
  render () {
    return (
      <div className='logo'>
        <Link to='/'>
          <img src={require('images/logo.png')} />
        </Link>
      </div>
    )
  }
}

export default AppbarLogo
