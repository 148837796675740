import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Gmaps, Marker, Circle } from 'react-gmaps'
import { getLatLong } from 'utils/utils.js'

class GoogleMap extends Component {
  constructor () {
    super()
    this.state = {
      latitude: null,
      longitude: null
    }
  }
  updateLatLongInState (props) {
    const _this = this
    getLatLong(props.address, function (latLong) {
      _this.setState({
        latitude: latLong.latitude,
        longitude: latLong.longitude
      })
    })
  }
  componentDidMount () {
    this.updateLatLongInState(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.updateLatLongInState(nextProps)
  }
  render () {
    const { latitude, longitude } = this.state
    function onMapCreated (map) {
      map.setOptions({
        disableDefaultUI: true
      })
    }

    return (
      <div className='map'>
        <Gmaps
          width={'100%'}
          height={'475px'}
          lat={latitude}
          lng={longitude}
          zoom={12}
          loadingMessage={'Loading'}
          params={{ v: '3.exp', key: 'AIzaSyAj62xfRUQjxV6erxIL6l84MSzCvYk2oAE' }}
          onMapCreated={onMapCreated}>
          <Marker
            lat={latitude}
            lng={longitude}
          />
          <Circle
            lat={latitude}
            lng={longitude}
            radius={500} />
        </Gmaps>
      </div>
    )
  }
}

GoogleMap.propTypes = {
  address: PropTypes.object
}

export default GoogleMap
