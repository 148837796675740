import React from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@material-ui/core'

class CsvUpload extends React.Component {
  constructor () {
    super()
    this.state = {
      filePath: ''
    }
  }
  static propTypes = {
    onFileUpload: PropTypes.func.isRequired,
    sampleCsvFilePath: PropTypes.any.isRequired
  }

  handleChange = (e) => {
    const file = e.target.files[0]
    this.setState({
      filePath: e.target.value
    }, () => {
      this.props.onFileUpload(file)
    })
  }
  render () {
    const filePath = this.state.filePath
    const fileName = filePath.split('\\').pop()
    return (
      <div className='csv-upload'>
        <Button
          variant='contained'
          color='primary'
          className='upload-btn'
        >
          <input
            type='file'
            className='file-input'
            onChange={this.handleChange}
          />
        UPLOAD CSV
        </Button>
        <TextField
          name='file'
          value={fileName}
          className='file-name'
          />
        <p className='tip-message'>Your csv file should follow the same format as
          <a href={this.props.sampleCsvFilePath} download='Sample.csv'>Sample.csv</a>
        </p>
      </div>
    )
  }
}

export default CsvUpload
