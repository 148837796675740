import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
const Modal = ({ ...props }) => {
  const { title, actions, children, open, closeModal, className, dividers } = props
  return (
    <Dialog open={open} onClose={closeModal} className={className} scroll='paper'
      aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
      <DialogTitle className='modal-header truncate'>{title}</DialogTitle>
      <DialogContent dividers={dividers}>{children}</DialogContent>
      <DialogActions className='modal-footer'>
        <Button color='primary' onMouseDown={closeModal}> CANCEL </Button>
        {actions}
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  actions: PropTypes.element,
  children: PropTypes.element.isRequired,
  dividers: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
}

export default Modal
