import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChipInput from 'components/ChipInput'

class EmailsChipInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    handleInvalidEmails: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {
      errors: {
        invalidEmails: []
      },
      chips: []
    }
  }

  isEmailValid (email) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return true
    }
  }

  handleChange (chips) {
    let errors = {
      invalidEmails: []
    }
    let newChip
    let emails = []
    let updatedChips = chips.map((chip) => {
      let email = chip.props ? chip.props.children : chip
      if (this.isEmailValid(email)) {
        newChip = <span>{email}</span>
        emails.push(email)
      } else {
        errors.invalidEmails.push(email)
        newChip = <span className='error'>{email}</span>
      }
      return newChip
    })

    this.setState({
      chips: updatedChips,
      errors: errors
    })
    this.props.onChange(emails)
    this.props.handleInvalidEmails(errors.invalidEmails)
  }

  render () {
    let invalidEmails = this.state.errors.invalidEmails.slice()
    let invalidEmailsError = invalidEmails.length ? <span>{invalidEmails.join(', ')} are invalid</span> : null
    return (
      <div>
        <p className='email-label'>Enter multiple email addresses</p>
        <div>
          <ChipInput
            chips={this.state.chips}
            onChange={(chips) => this.handleChange(chips)}
            />
          <div className='tip-message'>Separate multiple email addresses by pressing
          enter <i className='icon-enter' /> </div>
          <div className='tip-message error'> {invalidEmailsError} </div>
        </div>
      </div>)
  }
}

export default EmailsChipInput
