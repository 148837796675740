import * as actionTypes from 'constants/actionTypes'

export const removeError = () => ({
  type: actionTypes.REMOVE_ERROR
})

const initialState = null
const errorReduer = (state = initialState, action) => {
  const { type } = action
  let rejectedAction = type.match(/.*_REJECTED/)
  rejectedAction = rejectedAction ? rejectedAction[0] : ''

  switch (type) {
    case actionTypes.REMOVE_ERROR:
      return null
    case rejectedAction:
      let response = JSON.parse(action.payload.response.request.response)
      let error = response.errors && response.errors[0] || response.message || ''
      return error
  }
  return state
}

export default errorReduer

/* NOTE: Handling error response as suggested here: http://stackoverflow.com/a/34482258 */
