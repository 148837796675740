export const ADD_MEMBERS = 'ADD_MEMBERS'

export const PENDING_INVITATIONS = 'PENDING_INVITAtIONS'

export const INVITE_AND_MAKE_OWNER = 'INVITE_AND_MAKE_OWNER'

export const OWNER_ALREADY_EXISTS = 'OWNER_ALREADY_EXISTS'

export const DELETE_USERS_CONFIRM = 'DELETE_USERS_CONFIRM'

export const MAKE_MAMBER_AS_OWNER = 'MAKE_MAMBER_AS_OWNER'

export const REMOVE_MEMBER_CONFIRM = 'REMOVE_MEMBER_CONFIRM'

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION'

export const DELETE_AGGREGATION_UNITS = 'DELETE_AGGREGATION_UNITS'

export const ASSIGN_TAG = 'ASSIGN_TAG'

export const UNPAIR_QR_CODE = 'UNPAIR_QR_CODE'

export const UNASSIGN_TAG = 'UNASSIGN_TAG'

export const ARCHIVED_REPORTS = 'ARCHIVED_REPORTS'

export const ADD_SUBSCRIBERS = 'ADD_SUBSCRIBERS'

export const REPORT_SUBSCRIBERS = 'REPORT_SUBSCRIBERS'

export const GETTING_STARTED_REQUEST = 'GETTING_STARTED_REQUEST'

export const CHANGE_STATE_CONFIRM_MODAL = 'CHANGE_STATE_CONFIRM_MODAL'

export const ERROR_MESSAGE_MODAL = 'ERROR_MESSAGE_MODAL'
