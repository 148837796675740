import axios from 'axios'
import * as actionTypes from 'constants/actionTypes'
import _ from 'underscore'
import { convertToCamelCase, convertToSnakeCase, downloadFileFromGetResponse } from 'utils/utils.js'
import { API_URL } from 'constants/configVariables'
import { INVITE_AND_MAKE_OWNER, MAKE_MAMBER_AS_OWNER, OWNER_ALREADY_EXISTS } from 'constants/modalTypes'

// Get organization info
const getOrganizationConfig = {
  transformResponse: [(data) => {
    const organizationData = convertToCamelCase(JSON.parse(data)).organization
    return organizationData
  }]
}
export function getOrganization (orgId) {
  return {
    type: actionTypes.FETCH_ORGANIZATION,
    payload: axios.get(`${API_URL}/organizations/${orgId}/`,
    getOrganizationConfig)
  }
}

// Select members for remove or delete account
export function selectMembers (members) {
  return {
    type: actionTypes.SELECT_MEMBERS,
    payload: members
  }
}

export const unSelectMembers = () => ({
  type: actionTypes.UNSELECT_MEMBERS
})

const editOrganizationAddressConfig = {
  transformRequest: [(data) => {
    const organizationData = convertToSnakeCase(data)
    return JSON.stringify(organizationData)
  }],
  transformResponse: [(data) => {
    const organizationData = convertToCamelCase(JSON.parse(data)).organization
    return organizationData
  }],
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
}

export function editOrganizationAddress (country, timezone, phoneNumber) {
  return (dispatch, getState) => {
    const organizationForm = getState().form.organizationForm.values
    const orgId = getState().organizationInfo.id

    const organization = {
      name: organizationForm.name,
      phoneNumber: phoneNumber,
      timezone: timezone,
      address: {
        line_1: organizationForm.line1,
        line_2: organizationForm.line2,
        city: organizationForm.city,
        state: organizationForm.state,
        zipCode: organizationForm.zipCode,
        country: country
      }
    }

    dispatch({
      type: actionTypes.PUT_ORGANIZATION,
      payload: axios.put(`${API_URL}/organizations/${orgId}/`,
      organization,
      editOrganizationAddressConfig)
    }).then(function () {
      window.history.back()
    })
  }
}

export function makeOwner ({ type, email }, callback) {
  return (dispatch, getState) => {
    let emailId, actionType
    const orgId = getState().organizationInfo.id

    if (type === 'member') {
      emailId = email
      actionType = actionTypes.POST_MAKE_OWNER
    } else {
      emailId = email
      actionType = actionTypes.POST_INVITE_AND_MAKE_OWNER
    }
    dispatch({
      type: actionType,
      payload: axios.post(`${API_URL}/organizations/${orgId}/owners/`, { email: emailId })
    }).then(() => {
      callback()
    })
  }
}

export let filteredUserDetails
export function checkBeforeMakeOwner (props, email) {
  return (dispatch, getState) => {
    let emailId = email.replace('+', '%2B')
    dispatch({
      type: actionTypes.CHECK_FOR_EXISTING_USER,
      payload: axios.get(`${API_URL}/users/_search/?filter=${emailId}`)
    }).then(response => {
      filteredUserDetails = _.find(response.action.payload.data, { email: email })
      if (!filteredUserDetails) {
        props.openModal(INVITE_AND_MAKE_OWNER)
      } else if (filteredUserDetails.role === 'owner') {
        props.openModal(OWNER_ALREADY_EXISTS, filteredUserDetails)
      } else if (filteredUserDetails.role === 'member') {
        props.openModal(MAKE_MAMBER_AS_OWNER, filteredUserDetails)
      }
    })
  }
}

export function deleteUser (userId) {
  return {
    type: actionTypes.DELETE_USER,
    payload: axios.delete(`${API_URL}/users/${userId}/`)
  }
}

export function updateUserName (user) {
  return {
    type: actionTypes.PATCH_USER,
    payload: axios.patch(`${API_URL}/users/${user.id}/`,
      {
        full_name: user.name
      })
  }
}
export const updateMemberNameInList = (member) => ({
  type: actionTypes.UPDATE_MEMBER_NAME_IN_LIST,
  payload: member
})

const addMemberConfig = {
  transformResponse: [(data) => {
    const newInvitation = convertToCamelCase(JSON.parse(data)).data
    return newInvitation
  }]
}

export function addMember ({ email, organizationId }) {
  return {
    type: actionTypes.POST_MEMBERS,
    payload: axios.post(`${API_URL}/organizations/${organizationId}/members/`, { email: email }, addMemberConfig)
  }
}

export function removeMember ({ membersId, organizationId }) {
  return {
    type: actionTypes.DELETE_MEMBERS,
    payload: axios.delete(`${API_URL}/organizations/${organizationId}/members/`, {
      params: {
        id: membersId.join(',')
      }
    })
  }
}

export function removeMembersFromList (membersId) {
  return {
    type: actionTypes.REMOVE_MEMBERS_FROM_LIST,
    payload: membersId
  }
}

export function removeInvitationFromList (invitationId) {
  return {
    type: actionTypes.REMOVE_INVITATION_FROM_LIST,
    payload: invitationId
  }
}

export function resendInvite ({ invitationId }) {
  return {
    type: actionTypes.PATCH_RESEND_INVITE,
    payload: axios.patch(`${API_URL}/invitations/${invitationId}/`,
      null,
      { }
    )
  }
}

export function deleteInvitation ({ invitationId }) {
  return {
    type: actionTypes.DELETE_INVITATION,
    payload: axios.delete(`${API_URL}/invitations/${invitationId}/`,
      null,
      { }
    )
  }
}

export function inviteOwner ({ request }) {
  return {
    type: actionTypes.DELETE_INVITATION,
    payload: axios.delete(`${API_URL}/invitations/`,
      request,
      editOrganizationAddressConfig
    )
  }
}

export const deleteOrganization = ({ organizationId, organizationInfo }) => ({
  type: actionTypes.DELETE_ORGANIZATION,
  payload: axios.delete(`${API_URL}/organizations/${organizationId}/`, { organizationInfo })
})

export const uploadMemberCsvFile = ({ organizationId, file }) => {
  var data = new FormData()
  data.append('file', file)
  // data.append('fileType', 'text/csv')

  return {
    type: actionTypes.POST_MEMBERS_CSV_UPLOAD,
    payload: axios.post(`${API_URL}/organizations/${organizationId}/members/`, data, {
      processData: false
    })
  }
}

export const organizationDetailsCsv = ({ organizationId }) => ({
  type: actionTypes.FETCH_ORGANIZATION_DETAILS_CSV,
  payload: axios.get(`${API_URL}/organizations/${organizationId}/`, {
    headers: {
      Accept: 'text/csv'
    }
  }).then(
    function (result) {
      // Got file download through get request working from this answer:
      // http://stackoverflow.com/a/24523253/4563109
      downloadFileFromGetResponse({ data: result.data, resource: 'organizationDetails' })
    }
  )
})
