import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import Modal from 'components/Modal'

const DeleteOrganizationConfirmModal = ({ ...props }) => {
  const { modalConfig } = props
  const { closeModal, organizationName } = props
  return (
    <Modal
      title='Are you sure?'
      open={modalConfig.open}
      closeModal={closeModal}
      className='delete-org-modal'
      dividers={false}
      children={
        <p>{organizationName} will be deactivated.</p>
      }
      actions={
        <Button
          color='primary'
          onClick={() => {
            closeModal()
            props.deleteOrganization()
          }}
        > DEACTIVATE </Button>
      }
    />
  )
}

DeleteOrganizationConfirmModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  modalConfig: PropTypes.object.isRequired,
  organizationName: PropTypes.string.isRequired,
  deleteOrganization: PropTypes.func.isRequired
}
export default DeleteOrganizationConfirmModal
