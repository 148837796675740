import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

export const ErrorMessageModal = ({ ...props }) => {
  props.closeModal()
  return (
    <Dialog open onClose={props.closeModal} className={props.className}
      aria-labelledby='modal-title' aria-describedby='modal-description'>
      <div>
        <DialogTitle className='modal-header truncate'>
          {<div className='modal-header truncate'>Action not possible!</div>}
        </DialogTitle>
        <DialogContent><p className='modal-message'>{props.errorMessage}</p></DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => {
            props.closeModal()
            props.removeError()
          }}> OKAY </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

ErrorMessageModal.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  closeModal: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default ErrorMessageModal
