import { WebAuth } from 'auth0-js'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN,
  AREA51_DOMAIN, ACCOUNTS_DOMAIN, INFRATAB_ORG_ID } from 'constants/configVariables'
import { getUserDetails, isToken, setToken,
 setTokenInAxiosHeader, parseHash, removeToken } from 'utils/AuthenticationUtils'
import _ from 'underscore'
import { getOrganization } from 'containers/OrganizationInfo/actionCreators'
import { convertToCamelCase } from 'utils/utils'

export default class AuthService {
  constructor (clientId, domain) {
    this.auth0 = new WebAuth({ clientID: clientId, domain: domain, leeway: 60 })
    this.isFirstRender = true
  }
  logout () {
    removeToken()
    this.logoutFromAuth0()
  }
  logoutFromAuth0 () {
    this.auth0.logout({
      returnTo: ACCOUNTS_DOMAIN,
      client_id: AUTH0_CLIENT_ID
    })
  }
  isUserNotBizdevThenRedirect (response, callback) {
    if (response.data.user.role !== 'bizdev' || !response.data.user.organization ||
     response.data.user.organization.id !== INFRATAB_ORG_ID) {
      removeToken()
      window.location = ACCOUNTS_DOMAIN
    }
    callback()
  }
  getOrganizationInfo (store, nextState) {
    if (_.isEmpty(store.getState().organizationInfo)) {
      const orgId = nextState.params.orgId
      store.dispatch(getOrganization(orgId))
    }
  }
  isTokenAvailableThenStore (authResult) {
    if (Object.keys(authResult) !== '' && authResult.accessToken && authResult.idToken) {
      setToken(authResult.idToken)
    } else {
      window.location = ACCOUNTS_DOMAIN
    }
  }
  checkSession (callback) {
    // Check for session exists in Auth0, if exists then use
    this.auth0.checkSession({
      scope: 'openid email user_metadata app_metadata offline_access',
      responseType: 'id_token token',
      redirectUri: AREA51_DOMAIN + '/handle_auth'
    }, (err, result) => {
      if (!err) {
        console.log(result)
        this.isTokenAvailableThenStore(result)
        setTokenInAxiosHeader()
      } else {
        removeToken()
        window.location = ACCOUNTS_DOMAIN
      }
      callback()
    })
  }
  getAuthenticationDetails (nextState, replace) {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      let authResult = parseHash(window.location.hash)
      authResult = convertToCamelCase(authResult)
      authResult && this.isTokenAvailableThenStore(authResult)
      replace('/')
    }
  }
  checkAuthentication (nextState, replace, callback) {
    if (!/handle_auth/.test(nextState.location.pathname)) {
      isToken() ? this.checkToken(callback) : this.checkSession(callback)
    } else {
      callback()
    }
  }
  checkToken (callback) {
    setTokenInAxiosHeader()
    if (this.isFirstRender) {
      getUserDetails().then(
        (response) => {
          response.status === 200
          ? this.isUserNotBizdevThenRedirect(response, callback)
          : this.checkSession(callback)
        }
      )
      this.isFirstRender = false
    } else {
      callback()
    }
  }
  checkSessionForLogout () {
    // if the token is not in session storage, there is nothing to check (i.e. the user is already logged out)
    if (!sessionStorage.getItem('id_token')) return

    this.auth0.checkSession({
      scope: 'openid email user_metadata app_metadata offline_access',
      responseType: 'id_token token',
      redirectUri: AREA51_DOMAIN + '/handle_auth'
    }, function (err, data) {
      if (err) {
        sessionStorage.removeItem('id_token')
        window.location.href = '/'
      }
    })
  }
}
export const auth = new AuthService(AUTH0_CLIENT_ID, AUTH0_DOMAIN)
setInterval(auth.checkSessionForLogout.bind(auth), 5000)
