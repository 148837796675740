import axios from 'axios'
import { API_URL } from 'constants/configVariables'

export const getUserDetails = () => {
  return axios.get(`${API_URL}/users/`)
}

export const loggedIn = () => {
  return !!getToken()
}

export const isToken = () => {
  return !!getToken()
}

export const setToken = (idToken) => {
  sessionStorage.setItem('id_token', idToken)
}

export const setTokenInAxiosHeader = () => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('id_token')}`
}

export const getToken = () => {
  return sessionStorage.getItem('id_token')
}

export const removeToken = () => {
  sessionStorage.removeItem('id_token')
}

export const parseHash = (hash) => {
  return hash.substr(1)
    .split('&')
    .map(el => el.split('='))
    .reduce((pre, cur) => { pre[cur[0]] = cur[1]; return pre }, {})
}
