import React from 'react'
import PropTypes from 'prop-types'
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, Grid } from '@material-ui/core'

const PendingInvitations = ({ ...props }) => {
  const { pendingInvitations } = props
  return (
    <List className='modal-scroll'>
      {
        pendingInvitations.map(invitation => (
          <ListItem key={invitation.id}>
            <ListItemText
              primary={<div className='primary-text'>{invitation.email}</div>}
              secondary={<span className='secondary-text'>has been invited</span>}
            />
            <ListItemSecondaryAction>
              <Grid className='invitation-action-button'>
                <Button
                  variant='contained'
                  color='primary'
                  className='btn-blue'
                  onClick={() => props.resendInvite({ invitationId: invitation.id })}
                > RESEND </Button>
                <Button
                  variant='contained'
                  color='primary'
                  className='btn-red'
                  onClick={() =>
                    props.deleteInvitation({
                      invitationId: invitation.id
                    }).then(function () {
                      props.removeInvitationFromList(invitation.id)
                    })
                  }
                > CANCEL </Button>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
        )
      )
    }
    </List>
  )
}

PendingInvitations.propTypes = {
  pendingInvitations: PropTypes.array
}
export default PendingInvitations
