import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'
import _ from 'underscore'

class JumpTo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: null
    }
    this.alphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
      'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
  }
  static propTypes = {
    letters: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }
  componentWillMount () {
    this.selectFirstLetter()
  }
  componentWillReceiveProps () {
    this.selectFirstLetter()
  }
  selectFirstLetter () {
    const firstLetter = _.first(this.props.letters)
    const index = _.indexOf(this.alphabets, firstLetter)
    this.setState({
      value: index + 1
    })
  }
  render () {
    const props = this.props

    return (
      <div className='jump-to-section'>
        <span className='jump-text'>Jump to</span>
        <Select
          className='jump-to-dropdown'
          value={this.state.value}
          onChange={this.handleChange}
        >
          {this.alphabets.map((letter, index) => {
            const disabled = !_.contains(props.letters, letter)
            return <MenuItem
              key={letter}
              value={index + 1}
              disabled={disabled}
              onClick={() => {
                var target = document.getElementById(letter)
                props.onClick(target)
              }}
            > {letter.toLocaleUpperCase()} </MenuItem>
          })}
        </Select>
      </div>
    )
  }
}

export default JumpTo
