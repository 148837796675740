import React from 'react'
import PropTypes from 'prop-types'
import { Fab } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

const FloatingAddButton = ({ ...props }) => {
  return (
    <div className='floating-button' onClick={() => props.onClick()}>
      <Fab color='primary' aria-label='add'>
        <AddIcon />
      </Fab>
    </div>
  )
}

FloatingAddButton.propTypes = {
  onClick: PropTypes.func
}
export default FloatingAddButton
