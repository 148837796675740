import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import moment from 'moment-timezone'
import _ from 'underscore'

class TimeZoneSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.timezone
    }
  }
  static propTypes = {
    timezone: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string
  }
  onTimezone = (data) => {
    if (data) {
      this.setState({ value: data.value })
      this.props.onChange(data)
    } else {
      this.setState({ value: null })
      this.props.onChange('')
    }
  }
  componentWillReceiveProps (nextProps) {
    if (this.props.timezone !== nextProps.timezone) {
      this.setState({ value: nextProps.timezone })
    }
  }
  render () {
    const timezones = moment.tz.names()
    let value, label
    const timezoneOffset = _.map(timezones, (timezone) => {
      value = timezone
      label = '(UTC' + moment.tz(timezone).format('Z') + ') ' + timezone
      return {
        value,
        label
      }
    })
    return (
      <Select
        name='form-field-name'
        value={this.state.value}
        options={timezoneOffset}
        onChange={this.onTimezone}
      />
    )
  }
}

export default TimeZoneSelector
