import { auth } from 'utils/AuthService'
import organizationInfo from './OrganizationInfo'

export default (store) => ({
  path : 'organizations/:orgId/info/(:action/(:memberId))',
  onEnter     : (nextState, replace, callback) => {
    auth.checkAuthentication(nextState, replace, callback)
  },
  component: organizationInfo
})
