import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Paper, CircularProgress } from '@material-ui/core'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import { sidebarToggle } from 'components/Sidebar/modules/sidebar.js'
import { getUserDetails } from 'redux/modules/user'
import { openModal, closeModal } from 'redux/modules/modal.js'
import { removeMember, deleteUser } from 'containers/OrganizationInfo/actionCreators'
import './CoreLayout.scss'
import 'styles/core.scss'

export class CoreLayout extends React.Component {

  static propTypes = {
    children: PropTypes.element.isRequired,
    user: PropTypes.object.isRequired,
    organizationInfo: PropTypes.object,
    sidebar: PropTypes.bool.isRequired,
    aggregationUnits: PropTypes.object,
    sidebarToggle: PropTypes.func.isRequired,
    params: PropTypes.object,
    route: PropTypes.object,
    location: PropTypes.object,
    loader: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    removeMember: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired
  }
  constructor () {
    super()
    this.state = {
      openSidebar: false
    }
  }
  componentWillMount () {
    this.props.getUserDetails()
  }

  componentDidUpdate () {
    let mainElement = ReactDOM.findDOMNode(this)
    this.props.loader ? mainElement.style.pointerEvents = 'none' : mainElement.style.pointerEvents = 'auto'
  }

  handleSidebarOpen = () => {
    this.setState({ openSidebar : !this.state.openSidebar })
  }

  render () {
    let children = null
    if (this.props.children) {
      children = React.cloneElement(this.props.children, {
        auth: this.props.route.auth
      })
    }
    return (
      <div>
        {this.props.location.pathname !== '/login'
        ? <div>
          <Header
            organizationInfo={this.props.organizationInfo}
            aggregationUnits={this.props.aggregationUnits}
            sidebarToggle={this.props.sidebarToggle}
            deleteUser={this.props.deleteUser}
            removeMember={this.props.removeMember}
            openModal={this.props.openModal}
            handleSidebarOpen={this.handleSidebarOpen}
          />
          <Sidebar
            user={this.props.user}
            location={this.props.location}
            organizationInfo={this.props.organizationInfo}
            sidebar={this.props.sidebar}
            params={this.props.params}
            auth={this.props.route.auth}
            sidebarToggle={this.props.sidebarToggle}
            openSidebar={this.state.openSidebar}
            handleSidebarOpen={this.handleSidebarOpen}
          />
          <div>
            {this.props.loader
              ? <Paper className='circular-loader-container'>
                <CircularProgress className='circular-loader' />
              </Paper>
              : null
            }
          </div>
        </div>
        : null
        }
        <div className='main-container'>
          {children}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  sidebarToggle,
  removeMember: removeMember,
  deleteUser: deleteUser,
  getUserDetails: getUserDetails,
  openModal: openModal,
  closeModal: closeModal
}

const mapStateToProps = (state) => ({
  loader: state.loader,
  user: state.user,
  location: state.location,
  sidebar: state.sidebar,
  organizationInfo: state.organizationInfo,
  aggregationUnits: state.aggregationUnits
})
export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)
