import { injectReducer } from 'redux/rootReducer'
import { auth } from 'utils/AuthService'

export default (store) => ({
  path: 'requests',
  onEnter (nextState, replace, callback) {
    auth.checkAuthentication(nextState, replace, callback)
  },
  getComponent (nextState, cb) {
    require.ensure([], (require) => {
      const Requests = require('./Requests').default
      const reducer = require('./reducer').default
      injectReducer(store, { key: 'requests', reducer })

      /*  Return getComponent   */
      cb(null, Requests)
    }, 'requests')
  }
})
