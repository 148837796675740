import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

const DeactivateOrganization = ({ ...props }) => (
  <div className='button-placeholder-center'>
    <Button
      className='btn-red'
      onClick={props.onClickDeactivateOrganization}
      variant='contained'
      color='primary'
    > DEACTIVATE ORGANIZATION </Button>
  </div>
)

DeactivateOrganization.propTypes = {
  onClickDeactivateOrganization: PropTypes.func.isRequired
}
export default DeactivateOrganization
