import axios from 'axios'
import { convertToCamelCase } from 'utils/utils'
import { API_URL } from 'constants/configVariables'

// action creator for fetch user
const getUserConfig = {
  transformResponse: [(data) => {
    const user = convertToCamelCase(JSON.parse(data)).user
    return user
  }]
}

export const getUserDetails = () => ({
  type: 'FETCH_USER',
  payload: axios.get(`${API_URL}/users/`, getUserConfig)
})

// Reduer
const initialState = {}
export default function userReducer (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_USER_PENDING':
      break
    case 'FETCH_USER_FULFILLED':
      return action.payload.data
    case 'FETCH_USER_REJECTED':
      break
  }
  return state
}
