import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import _ from 'underscore'
import OrganizationAddress from './OrganizationAddress'
import TransferOwnership from './TransferOwnership'
import DeactivateOrganization from './DeactivateOrganization'
import OrganizationAddressEdit from './OrganizationAddressEdit'
import MemberEdit from './MemberEdit'

const OrganizationInfoRightSection = ({ ...props }) => {
  const { action, memberId } = props.params
  const { organization } = props
  const organizationState = props.organizationState || ''
  let { address, members } = organization
  address = address || {}
  members = members || []

  const organizationInitialValues = {
    initialValues : {
      name: organization.name,
      city: address.city,
      line1: address.line1,
      line2: address.line2,
      state: address.state,
      zipCode: address.zipCode,
      timezone: organization.timezone
    }
  }

  const allMembers = members.concat(organization.owner)
  const member = _.findWhere(allMembers, { id: Number(memberId) }) || {}
  let initialValues = {
    initialValues: member
  }
  return (
    <div className='right-section'>
      { action === 'show_address'
        ? <OrganizationAddress organization={props.organization} />
        : null
      }
      { (action === 'edit_address' && !_.isEmpty(props.organization))
        ? <OrganizationAddressEdit
          {...organizationInitialValues}
          organization={props.organization}
          editOrganizationAddress={props.editOrganizationAddress}
        />
        : null
      }
      { action === 'edit_member'
        ? <MemberEdit
          {...initialValues}
          params={props.params}
          organization={props.organization}
          openModal={props.openModal}
          closeModal={props.closeModal}
          makeOwner={props.makeOwner}
          deleteUser={props.deleteUser}
          onSubmit={(values) => props.updateUserName(values).then(() => {
            props.updateMemberNameInList(values)
            browserHistory.push(`/organizations/${organization.id}/info/show_address/`)
          })}
          />
        : null
      }
      { organizationState !== 'INACTIVE'
        ? <DeactivateOrganization
          onClickDeactivateOrganization={props.onClickDeleteOrganization}
        />
        : null
      }
      <TransferOwnership
        organization={props.organization}
        makeOwner={props.makeOwner}
        openModal={props.openModal}
        closeModal={props.closeModal}
        modalConfig={props.modalConfig}
        newOwner={props.organization.newOwner}
        onSubmit={(values) => props.checkBeforeMakeOwner(props, values.email)} />
    </div>
  )
}

OrganizationInfoRightSection.propTypes = {
  organization: PropTypes.object,
  params: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  makeOwner: PropTypes.func.isRequired,
  checkBeforeMakeOwner: PropTypes.func.isRequired,
  updateUserName: PropTypes.func.isRequired,
  editOrganizationAddress: PropTypes.func.isRequired,
  organizationState: PropTypes.string,
  modalConfig: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  updateMemberNameInList: PropTypes.func.isRequired,
  onClickDeleteOrganization: PropTypes.func.isRequired
}

OrganizationInfoRightSection.defaultProps = {
  organization: {
    members: [],
    address: {}
  }
}

export default OrganizationInfoRightSection
