import CoreLayout from 'layouts/CoreLayout/CoreLayout'
import OrganizationList from 'containers/pages/OrganizationList'
import OrganizationInfo from 'containers/OrganizationInfo'
import OrganzationTags from 'containers/OrganizationTags'
import AggregationUnits from 'containers/AggregationUnits'
import HandleAuth from 'containers/HandleAuth'
import Reports from 'containers/Reports'
import Requests from 'containers/Requests'
import { auth } from 'utils/AuthService'
import { interceptorBeforeAndAfterAxiosCall } from 'utils/utils'

export const createRoutes = (store) => ({
  path        : '/',
  onEnter     : (nextState, replace, callback) => {
    interceptorBeforeAndAfterAxiosCall(store)
    auth.checkAuthentication(nextState, replace, callback)
  },
  component   : CoreLayout,
  indexRoute  : OrganizationList(store),
  childRoutes : [
    HandleAuth(store),
    OrganizationInfo(store),
    OrganzationTags(store),
    AggregationUnits(store),
    Reports(store),
    Requests(store)
  ]
})

export default createRoutes
