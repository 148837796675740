import React from 'react'
import ReactDOM from 'react-dom'
import createStore from 'redux/createStore'
import AppContainer from 'containers/AppContainer'
import { RAVEN_CONFIG } from 'constants/configVariables'
import Raven from 'raven-js'

// injectTapEventPlugin()

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)
const environment = process.env.NODE_ENV

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = () => {
  const routes = require('./routes/index').default(store)

  ReactDOM.render(
    <AppContainer store={store} routes={routes} />,
    MOUNT_NODE
  )
}

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.devToolsExtension) {
    window.devToolsExtension.open()
  }
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      }, 0)
    )
  }
}

// Configure sentry
Raven
  .config(RAVEN_CONFIG[environment].DNS, { environment: RAVEN_CONFIG[environment].environment })
  .install()

// ========================================================
// Go!
// ========================================================
render()
