export const openModal = (modalType, data) => ({
  type: 'OPEN_MODAL',
  payload: {
    modalType,
    data
  }
})

export const closeModal = () => ({
  type: 'CLOSE_MODAL'
})

const initialState = {
  open: false,
  data: {}
}

export default function modalReducer (state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      const modalConfig = {
        open: true,
        type: action.payload.modalType,
        data: action.payload.data
      }
      return modalConfig
    case 'CLOSE_MODAL':
      return initialState
  }
  return state
}
