import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import MemberList from './Components/MemberList'
import OrganizationInfoRightSection from './Components/OrganizationInfoRightSection'
import { openModal, closeModal } from 'redux/modules/modal'
import AddMembersModal from './Components/AddMembersModal'
import MakeMemberAsOwnerModal from './Components/MakeMemberAsOwnerModal'
import DeleteUsersConfirmModal from './Components/DeleteUsersConfirmModal'
import RemoveMemberConfirmModal from './Components/RemoveMemberConfirmModal'
import ErrorMessageModal from 'components/ErrorMessageModal'
import DeleteOrganizationConfirmModal from './Components/DeleteOrganizationConfirmModal'
import { ADD_MEMBERS, MAKE_MAMBER_AS_OWNER, DELETE_USERS_CONFIRM, REMOVE_MEMBER_CONFIRM,
  DELETE_ORGANIZATION } from 'constants/modalTypes'
import * as actionCreators from './actionCreators'
import { removeError } from 'redux/modules/error'
import { errorMessage } from './errors'

const { editOrganizationAddress, makeOwner } = actionCreators

class OrganizationInfo extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    organizationInfo: PropTypes.object.isRequired,
    getOrganization: PropTypes.func.isRequired,
    selectMembers: PropTypes.func.isRequired,
    unSelectMembers: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    makeOwner: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    addMember: PropTypes.func.isRequired,
    editOrganizationAddress: PropTypes.func.isRequired,
    updateMemberNameInList: PropTypes.func.isRequired,
    uploadMemberCsvFile: PropTypes.func.isRequired,
    organizationDetailsCsv: PropTypes.func.isRequired,
    deleteOrganization: PropTypes.func.isRequired
  }

  componentWillMount () {
    const orgId = this.props.params.orgId
    this.props.getOrganization(orgId)
  }
  componentWillUnmount () {
    this.props.unSelectMembers()
  }
  render () {
    const props = this.props
    const { id, name } = props.organizationInfo
    const organizationState = props.location.state || ''
    return (
      <div>
        <MemberList organization={props.organizationInfo}
          selectMembers={props.selectMembers}
          openModal={props.openModal}
          closeModal={props.closeModal}
          makeOwner={props.makeOwner}
          addMember={props.addMember}
          removeMember={props.removeMember}
          removeMembersFromList={props.removeMembersFromList}
          removeInvitationFromList={props.removeInvitationFromList}
          modalConfig={props.modalConfig}
          resendInvite={props.resendInvite}
          deleteInvitation={props.deleteInvitation}
          organizationDetailsCsv={props.organizationDetailsCsv}
        />
        <OrganizationInfoRightSection
          organization={props.organizationInfo}
          params={props.params}
          openModal={props.openModal}
          closeModal={props.closeModal}
          makeOwner={props.makeOwner}
          checkBeforeMakeOwner={props.checkBeforeMakeOwner}
          deleteUser={props.deleteUser}
          organizationState={organizationState}
          updateUserName={props.updateUserName}
          editOrganizationAddress={props.editOrganizationAddress}
          modalConfig={props.modalConfig}
          updateMemberNameInList={props.updateMemberNameInList}
          onClickDeleteOrganization={() =>
            props.openModal(DELETE_ORGANIZATION, { organizationId: props.organizationInfo.id })
          }
        />
        {props.modalConfig.type === ADD_MEMBERS
        ? <AddMembersModal
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          addMember={props.addMember}
          organizationId={props.organizationInfo.id}
          uploadMemberCsvFile={props.uploadMemberCsvFile}
          getOrganization={props.getOrganization}
          />
        : null
        }
        {props.modalConfig.type === MAKE_MAMBER_AS_OWNER
        ? <MakeMemberAsOwnerModal
          organization={props.organizationInfo}
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          makeOwner={props.makeOwner}
          />
        : null
        }
        {props.modalConfig.type === DELETE_USERS_CONFIRM
        ? <DeleteUsersConfirmModal
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          deleteUser={props.deleteUser}
          organizationId={props.organizationInfo.id}
          removeMembersFromList={props.removeMembersFromList}
          />
        : null
        }
        {props.modalConfig.type === REMOVE_MEMBER_CONFIRM
        ? <RemoveMemberConfirmModal
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          removeMember={props.removeMember}
          removeMembersFromList={props.removeMembersFromList}
          organizationId={props.organizationInfo.id}
          />
        : null
        }
        {props.modalConfig.type === DELETE_ORGANIZATION
        ? <DeleteOrganizationConfirmModal
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          organizationName={props.organizationInfo.name}
          organizationId={props.organizationInfo.id}
          deleteOrganization={() =>
            props.deleteOrganization({ organizationId: props.organizationInfo.id }).then(() => {
              closeModal()
              browserHistory.push('/')
            })
          }
          />
        : null
        }
        {props.error
        ? <ErrorMessageModal
          className='org-info-error-msg-modal'
          errorMessage={errorMessage(props.error, { orgId: id, name: name })}
          closeModal={props.closeModal}
          removeError={props.removeError} />
        : null
        }
      </div>
    )
  }
}

const mapDispatchToProps = {
  selectMembers: actionCreators.selectMembers,
  unSelectMembers: actionCreators.unSelectMembers,
  getOrganization: actionCreators.getOrganization,
  openModal: openModal,
  closeModal: closeModal,
  editOrganizationAddress,
  makeOwner,
  checkBeforeMakeOwner: actionCreators.checkBeforeMakeOwner,
  updateUserName: actionCreators.updateUserName,
  deleteUser: actionCreators.deleteUser,
  addMember: actionCreators.addMember,
  removeMember: actionCreators.removeMember,
  removeMembersFromList: actionCreators.removeMembersFromList,
  removeInvitationFromList: actionCreators.removeInvitationFromList,
  resendInvite: actionCreators.resendInvite,
  deleteInvitation: actionCreators.deleteInvitation,
  updateMemberNameInList: actionCreators.updateMemberNameInList,
  uploadMemberCsvFile: actionCreators.uploadMemberCsvFile,
  organizationDetailsCsv: actionCreators.organizationDetailsCsv,
  deleteOrganization: actionCreators.deleteOrganization,
  removeError: removeError
}

const mapStateToProps = (state) => ({
  organizationInfo: state.organizationInfo,
  modalConfig: state.modalConfig,
  error: state.error
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationInfo)
