import React from 'react'
import PropTypes from 'prop-types'
import { Button, AppBar, Toolbar, Grid, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ActionBar from 'components/ActionBar'
import AppbarLogo from 'components/AppbarLogo'
import { REMOVE_MEMBER_CONFIRM, DELETE_USERS_CONFIRM, DELETE_AGGREGATION_UNITS } from 'constants/modalTypes'

const Header = ({ ...props }) => {
  const { handleSidebarOpen } = props
  const { organizationInfo, aggregationUnits } = getDefaultsProps(props)
  const { selectedMembers } = organizationInfo
  const { selectedAggregationUnits } = aggregationUnits
  const actionButtonsForSelectedMembers = (
    <div>
      <Button
        className='remove-button-icon'
        onClick={() => props.openModal(REMOVE_MEMBER_CONFIRM, { members: selectedMembers })}
      > REMOVE </Button>
      <Button
        className='delete-button-icon'
        onClick={() => props.openModal(DELETE_USERS_CONFIRM, { members: selectedMembers })}
      > DELETE USERS </Button>
    </div>
  )
  const actionButtonsForSelectedAggregationUnits = (
    <div>
      <Button
        className='delete-button-icon'
        onClick={() => props.openModal(DELETE_AGGREGATION_UNITS, { aggregationUnits: selectedAggregationUnits })}
      > DELETE </Button>
    </div>
  )

  return (
    <div>
      {
        !selectedMembers.length && !selectedAggregationUnits.length
        ? <div className='app-bar-fixed'>
          {/* <AppBar
            className='app-bar'
            title={<AppbarLogo />}
            iconClassNameRight='muidocs-icon-navigation-expand-more'
            onLeftIconButtonTouchTap={sidebarToggle}
          /> */}
          <AppBar position='fixed' className='app-bar' >
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                onClick={handleSidebarOpen}>
                <MenuIcon />
              </IconButton>
              <Grid item xs={12} sm={6} >
                <AppbarLogo />
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
        : <div className='action-app-bar'>
          {selectedMembers.length
          ? <ActionBar
            backButton={<span><i className='icon-backarrow icon' /> Back</span>}
            selectedItems={selectedMembers}
            >
            { actionButtonsForSelectedMembers }
          </ActionBar>
          : null
          }
          {selectedAggregationUnits.length
          ? <ActionBar
            backButton={<span><i className='icon-backarrow icon' /> Back</span>}
            selectedItems={selectedAggregationUnits}
            >
            { actionButtonsForSelectedAggregationUnits }
          </ActionBar>
          : null
          }
        </div>
    }
    </div>
  )
}

Header.propTypes = {
  sidebarToggle: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  handleSidebarOpen: PropTypes.func.isRequired
}

const getDefaultsProps = (props) => {
  const organizationInfo = {
    selectedMembers: []
  }
  const aggregationUnits = {
    selectedAggregationUnits: []
  }
  let defaultState = Object.assign({}, props)
  const defaults = Object.assign({}, defaultState, { organizationInfo: organizationInfo })
  if (!props.organizationInfo) {
    defaultState = defaults
  } else if (!props.organizationInfo.selectedMembers) {
    defaultState = defaults
  }
  if (!props.aggregationUnits) {
    defaultState = Object.assign({}, defaultState, { aggregationUnits: aggregationUnits })
  }

  return defaultState
}

export default Header
