import { injectReducer } from 'redux/rootReducer'
import { auth } from 'utils/AuthService'

export default (store) => ({
  onEnter (nextState, replace, callback) {
    auth.checkAuthentication(nextState, replace, callback)
  },
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const OrganizationList = require('./OrganizationList').default
      const reducer = require('./reducer').default
      const getOrganizations = require('./actionCreators').getOrganizations
      injectReducer(store, { key: 'organizations', reducer })

      store.dispatch(getOrganizations()).then(() => {
        cb(null, OrganizationList)
      })

     /* Webpack named bundle   */
    }, 'OrganizationList')
  }
})
