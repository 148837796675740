import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import { Button } from '@material-ui/core'
import { browserHistory } from 'react-router'
import Modal from 'components/Modal'

const RemoveMemberConfirmModal = ({ ...props }) => {
  const { modalConfig, closeModal, removeMember } = props
  const members = modalConfig.data.members
  const membersId = _.pluck(members, 'id')
  return (
    <Modal
      title='Are you sure?'
      open={modalConfig.open}
      closeModal={closeModal}
      className='remove-member-confirmation'
      dividers={false}
      children={
        <p>Removing the members from an organization can not be undone. You can only re-add them again later</p>
      }
      actions={<Button
        color='primary'
        onClick={() => {
          closeModal()
          removeMember({ membersId, organizationId: props.organizationId }).then(() => {
            browserHistory.push(`/organizations/${props.organizationId}/info/show_address/`)
            props.removeMembersFromList(membersId)
          })
        }}
      > {`REMOVE ${members.length} USERS`} </Button>}
    />
  )
}

RemoveMemberConfirmModal.propTypes = {
  modalConfig: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  organizationId: PropTypes.string,
  removeMembersFromList: PropTypes.func.isRequired
}

export default RemoveMemberConfirmModal
