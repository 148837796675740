import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import Modal from 'components/Modal'

const MakeMemberAsOwnerModal = ({ ...props }) => {
  const { modalConfig, organization, closeModal, makeOwner } = props
  return (
    <Modal
      title='Are you sure?'
      open={modalConfig.open}
      closeModal={closeModal}
      dividers={false}
      className='member-as-owner-modal'
      children={
        !modalConfig.data.organization
        ? <p className='make-owner-message'> <span>{`${modalConfig.data.name}`}</span> will be made as owner of <span>
          {`${organization.name}`}</span> </p>
        : <p className='make-owner-message'> <span>{`${modalConfig.data.email}`}</span> is already a member of <span>
          {`${modalConfig.data.organization.name}`} </span> as <span> {`${modalConfig.data.full_name}`}.</span>
            Are you sure to make this as the owner of <span> {`${organization.name}`} ? </span> </p>
      }
      actions={<Button color='primary' onClick={() => {
        closeModal()
        makeOwner({ type: 'member', email:modalConfig.data.email })
      }}> CONFIRM </Button>}
    />
  )
}

MakeMemberAsOwnerModal.propTypes = {
  member: PropTypes.object,
  organization: PropTypes.object,
  makeOwner: PropTypes.func.isRequired,
  modalConfig: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
}

export default MakeMemberAsOwnerModal
