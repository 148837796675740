import _ from 'underscore'
import * as actionTypes from 'constants/actionTypes'
import { filteredUserDetails } from './actionCreators'

const intitialState = {}

export default function organizationInfoReducer (state = intitialState, action) {
  let currentState, members, selectedMembers, membersId, invitations, invitationId
  switch (action.type) {

    case actionTypes.FETCH_ORGANIZATION_FULFILLED:
      return action.payload.data

    case actionTypes.PUT_ORGANIZATION_FULFILLED:
      const data = Object.assign({}, state, action.payload.data)
      return data

    case actionTypes.SELECT_MEMBERS:

      currentState = Object.assign({}, state)
      selectedMembers = currentState.selectedMembers || []
      const member = action.payload

      if (_.findWhere(selectedMembers, { id: member.id })) {
        selectedMembers = _.without(selectedMembers, member)
      } else {
        selectedMembers.push(member)
      }

      return Object.assign({}, state, { selectedMembers: selectedMembers })

    case actionTypes.UNSELECT_MEMBERS:
      return Object.assign({}, state, { selectedMembers: [] })

    case actionTypes.REMOVE_MEMBERS_FROM_LIST:
      membersId = action.payload
      const oldState = Object.assign({}, state)
      members = oldState.members
      selectedMembers = oldState.selectedMembers
      const remainingMembers = _.filter(members, (member) => !_.contains(membersId, member.id))
      const remainingSelectedMembers = _.filter(selectedMembers, member => _.contains((membersId, member.id)))
      return Object.assign({}, state, { members: remainingMembers }, { selectedMembers: remainingSelectedMembers })

    case actionTypes.REMOVE_INVITATION_FROM_LIST:
      invitationId = action.payload
      const oldStateValues = Object.assign({}, state)
      invitations = oldStateValues.invitations.pending
      const remainingInvitations = _.filter(invitations, (invitation) => invitationId !== invitation.id)
      const invitationData = {
        pending: remainingInvitations
      }
      return Object.assign({}, state, { invitations: invitationData })

    case actionTypes.POST_MEMBERS_FULFILLED:
      let pendingInvitations, pendinginvites
      const newMemberInvite = action.payload.data
      currentState = { ...state }
      pendingInvitations = currentState.invitations.pending
      pendingInvitations.push({ ...newMemberInvite, isValid: true, isInvitationAccepted: false })
      pendinginvites = { pending: pendingInvitations }
      return { ...state, invitations: pendinginvites }

    case actionTypes.UPDATE_MEMBER_NAME_IN_LIST:
      const updatedMember = action.payload
      currentState = { ...state }
      members = currentState.members.map(member => member)
      let owner = Object.assign({}, currentState.owner)

      if (updatedMember.role === 'owner') {
        owner = updatedMember
      } else {
        const memberIndex = _.findIndex(members, { id: updatedMember.id })
        members[memberIndex].name = updatedMember.name
      }

      return { ...currentState, members: members, owner: owner }

    case actionTypes.POST_MEMBERS_CSV_UPLOAD_FULFILLED:
      const newInvitedMembers = action.payload.data.members
      currentState = Object.assign({}, state)
      let pendingInvitation = currentState.invitations.pending
      _.each(newInvitedMembers, (member) => {
        pendingInvitation.push({ ...member, isValid: true, isInvitationAccepted: false })
      })
      return { ...state, invitations : { pending : pendingInvitation } }

    case actionTypes.POST_MAKE_OWNER_FULFILLED:
      let ownerEmail = JSON.parse(action.payload.config.data).email
      currentState = Object.assign({}, state)
      members = state.members
      const currentOwner = state.owner
      const updatedMembers = []
      const updated = state.members.map((member) => {
        if (member.email !== ownerEmail) {
          updatedMembers.push(member)
        } else {
          owner = {
            email: member.email,
            id: member.id,
            name: member.name,
            role: 'owner'
          }
        }
      })
      if (!owner) {
        owner = {
          email: filteredUserDetails.email,
          id: filteredUserDetails.id,
          name: filteredUserDetails.full_name,
          role: 'owner'
        }
      }
      updatedMembers.push({
        email: currentOwner.email,
        id: currentOwner.id,
        isArchived: false,
        name: currentOwner.name,
        role: 'member'
      })

      return Object.assign({}, state,
       { members: updatedMembers, owner: owner })

    case actionTypes.POST_INVITE_AND_MAKE_OWNER_FULFILLED:
      owner = JSON.parse(action.payload.config.data).email
      currentState = Object.assign({}, state)
      pendingInvitations = currentState.invitations.pending
      pendingInvitations.push({ email: owner, id: owner, isValid: true, isInvitationAccepted: false })
      pendinginvites = { pending: pendingInvitations }
      console.log(pendinginvites)
      return Object.assign({}, state, { invitations: pendinginvites })
  }
  return state
}
