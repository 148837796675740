import { SHOW_LOADER } from 'constants/actionTypes'

// ------------------------------------
// Actions
// ------------------------------------
export function showLoader (value = false) {
  return {
    type    : SHOW_LOADER,
    payload : value
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SHOW_LOADER]    : (state, action) => action.payload
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function loaderReducer (state = false, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
