import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import _ from 'underscore'
import { Button, TextField, Divider } from '@material-ui/core'
import validate from 'validators/FormValidators'
import { reduxForm, Field } from 'redux-form'
import { DELETE_USERS_CONFIRM, MAKE_MAMBER_AS_OWNER } from 'constants/modalTypes'

class MemberEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      memberName: ''
    }
  }
  handleChange (e) {
    this.setState({ memberName: e.target.value })
  }
  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      helperText={touched && error}
      error={touched && error}
      {...input}
      {...custom}
    />
  )
  render () {
    const props = this.props
    const { organization } = props
    const { memberId } = props.params
    let members = organization.members
    members = members ? members.concat(organization.owner) : [organization.owner]
    const member = _.findWhere(members, { id: Number(memberId) })
    return (
      <div>
        <Link to={`/organizations/${organization.id}/info/show_address/`}>
          <i className='icon-backarrow action-icon' /> Back</Link>
        <div className='member-edit'>
          <form onSubmit={props.handleSubmit} >
            <Field
              className='input-field'
              name='name'
              component={this.renderTextField}
              label='Full Name'
              onChange={(e) => { this.handleChange(e) }}
            />
            <Field
              name='email'
              component={this.renderTextField}
              className='input-field'
              disabled
              label='Email id'
            />
            <div className='button-placeholder'>
              <Link to={`/organizations/${organization.id}/info/show_address/`}>
                <Button variant='contained' color='primary' className='btn-darkyellow'> CANCEL </Button>
              </Link>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                className='btn-darkcyan'
                disabled={this.state.memberName === member.name || this.state.memberName === ''}
              > UPDATE </Button>
            </div>
          </form>
        </div>

        { member.role === 'member'
        ? (<div>
          <Divider />
          <div className='member-btn-section'>
            <div className='make-mem-btn button-placeholder-center'>
              <Button
                className='btn-darkcyan'
                variant='contained'
                color='primary'
                onClick={() => props.openModal(MAKE_MAMBER_AS_OWNER, member)}
              > MAKE OWNER </Button>

            </div>
            <div className='button-placeholder-center'>
              <Button
                className='btn-red'
                variant='contained'
                color='primary'
                onClick={() => props.openModal(DELETE_USERS_CONFIRM, { members: [member] })}
              > DELETE USER ACCOUNT </Button>
            </div>
          </div>
        </div>)
        : null
      }
      </div>
    )
  }
}

MemberEdit.propTypes = {
  organization: PropTypes.object,
  params: PropTypes.object,
  openModal: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'editMember',
  validate,
  enableReinitialize: true
})(MemberEdit)
