import { injectReducer } from 'redux/rootReducer'
import { auth } from 'utils/AuthService'

export default (store) => ({
  path : 'organizations/:orgId/tags/',
  onEnter (nextState, replace, callback) {
    auth.checkAuthentication(nextState, replace, callback)
    auth.getOrganizationInfo(store, nextState)
  },
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const OrganizationTags = require('./OrganizationTags').default
      const reducer = require('./reducer').default
      injectReducer(store, { key: 'organizationTags', reducer })

      /*  Return getComponent   */
      cb(null, OrganizationTags)

    /* Webpack named bundle   */
    }, 'organizationTags')
  }
})
