import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import _ from 'underscore'
import GoogleMap from 'components/GoogleMap'
import { Button, Divider, Icon } from '@material-ui/core'

const OrganizationAddress = ({ ...props }) => {
  const organization = getDefaults(props.organization)
  const { phoneNumber, timezone } = organization
  const { line1, line2, city, state, country, zipCode } = organization.address

  return (
    <div className='address-section'>
      <h2> Organization Info </h2>
      <Divider />
      <h3 className='truncate'> {organization.name} </h3>
      <p><Icon
        className='fa fa-map icon' /> <span>{line1} </span></p>
      <p> {line2} </p>
      <p> {city} </p>
      <p> {state} </p>
      <p> {country} </p>
      <p> {zipCode} </p>
      <p><Icon
        className='fa fa-phone icon' /> <span>{phoneNumber}</span></p>
      <p><Icon
        className='icon-globe-line icon' /> <span>{timezone}</span></p>
      <GoogleMap address={organization.address} />
      <div className='edit-btn-section'>
        <Link to={`/organizations/${organization.id}/info/edit_address/`}>
          <Button className='btn-darkorange' variant='contained' color='primary'> EDIT </Button>
        </Link>
      </div>
    </div>
  )
}

const getDefaults = (organization) => {
  const defaults = {
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      zipCode: ''
    }
  }
  if (_.isEmpty(organization)) {
    return Object.assign({}, organization, defaults)
  } else {
    return organization
  }
}

OrganizationAddress.propTypes = {
  organization: PropTypes.object
}

export default OrganizationAddress
