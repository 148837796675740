import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'
import locationReducer from './modules/location'
import sidebarReducer from 'components/Sidebar/modules/sidebar'
import userReducer from './modules/user'
import loaderReducer from './modules/loader'
import showLoaderReducer from 'store/loader'
import modalConfig from './modules/modal'
import errorReducer from './modules/error'
import organizationInfoReducer from 'containers/OrganizationInfo/reducer'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    location: locationReducer,
    form: reduxFormReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    loader:loaderReducer,
    showLoader:showLoaderReducer,
    modalConfig: modalConfig,
    error: errorReducer,
    organizationInfo: organizationInfoReducer,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
