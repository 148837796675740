import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import Modal from 'components/Modal'
import EmailsChipInput from 'components/EmailsChipInput'
import CsvUpload from 'components/CsvUpload'

class AddMembersModal extends Component {
  constructor () {
    super()
    this.state = {
      emails : [],
      csvFile : '',
      isEmailValid: true
    }
  }
  static propTypes = {
    modalConfig: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    organizationId: PropTypes.string,
    uploadMemberCsvFile: PropTypes.func.isRequired,
    getOrganization: PropTypes.func.isRequired,
    addMember: PropTypes.func.isRequired
  }
  handleInvalidEmails = (invalidEmails) => {
    this.setState({ isEmailValid: invalidEmails.length === 0 })
  }
  onAddMembersClick () {
    this.props.closeModal()
    if (this.state.csvFile) {
      this.props.uploadMemberCsvFile({
        organizationId: this.props.organizationId,
        file: this.state.csvFile
      })
      .then(() => this.props.getOrganization(this.props.organizationId))
    }
    if (this.state.emails.length) {
      Promise.all(this.state.emails.map((email) => this.props.addMember({ email:email, organizationId: this.props.organizationId })))
      .then(() => this.props.getOrganization(this.props.organizationId))
    }
  }
  render () {
    return (
      <Modal
        title='Add members'
        open={this.props.modalConfig.open}
        closeModal={this.props.closeModal}
        dividers
        className='add-members-modal'
        children={<div className='modal-scroll'>
          <EmailsChipInput
            onChange={(chipValues) => {
              this.setState({ emails: chipValues })
            }}
            handleInvalidEmails={this.handleInvalidEmails}
          />
          <div className='badge'>
            <span>Bulk Add Members</span>
          </div>
          <CsvUpload
            onFileUpload={(file) => {
              this.setState({ csvFile: file })
            }}
            sampleCsvFilePath={require('static/csv/add_members_sample.csv')}
          />
        </div>}
        actions={<Button
          color='primary'
          disabled={!this.state.emails.length && !this.state.csvFile || !this.state.isEmailValid}
          onClick={() => {
            this.onAddMembersClick()
          }}
        > ADD MEMBERS </Button>}
      />
    )
  }
}

export default AddMembersModal
