export const errorMessage = (error, data) => {
  let errorMessage
  const { meta } = error
  switch (error.code) {
    case '400200243':
      const { orgName } = data
      errorMessage = `${meta.user.email} is already the owner of ${meta.org.name}.` +
      `Transfer the ownership from ${meta.user.email} before attempting to make them the owner of ${orgName}`
      return errorMessage
    case '400200244':
      errorMessage = `${meta.user.email} is owner of the same organization`
      return errorMessage
    default:
      return error.message
  }
}
