import { injectReducer } from 'redux/rootReducer'
import { auth } from 'utils/AuthService'

export default (store) => ({
  path: 'organizations/:orgId/reports/',
  onEnter (nextState, replace, callback) {
    auth.checkAuthentication(nextState, replace, callback)
    auth.getOrganizationInfo(store, nextState)
  },
  getComponent (nextState, cb) {
    require.ensure([], () => {
      const Reports = require('./Reports').default
      const reducer = require('./reducer').default
      injectReducer(store, { key: 'reports', reducer })

      /*  Return getComponent   */
      cb(null, Reports)
    }, 'OrganizationReports')
  }
})
