import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, reset } from 'redux-form'
import Modal from 'components/Modal'
import validate from 'validators/FormValidators'
import { INVITE_AND_MAKE_OWNER, OWNER_ALREADY_EXISTS } from 'constants/modalTypes'
import { Button, Divider, TextField } from '@material-ui/core'

class TransferOwnership extends React.Component {
  constructor () {
    super()
    this.state = {
      email : ''
    }
    this.emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
  }
  handleChange (e, field) {
    this.setState({ email: e.target.value })
  }
  enableSubmission () {
    return this.emailPattern.test(this.state.email)
  }
  renderTextField = ({ input, meta: { touched, error }, ...custom }) => (
    <TextField
      label='Enter email id'
      placeholder='Email'
      helperText={touched && error}
      error={touched && error}
      {...input}
      {...custom}
    />
  )
  render () {
    return (
      <div className='transfer-section'>
        <h2>Transfer Ownership</h2>
        <Divider />
        <form onSubmit={this.props.handleSubmit}>
          <Field
            className='input-field'
            name='email'
            component={this.renderTextField}
            onChange={(e) => { this.handleChange(e) }}
            />
          <br />
          <Button
            type='submit'
            variant='contained'
            className='btn-darkcyan mk-owner-btn'
            color='primary'
            disabled={!this.enableSubmission()}
            // onClick={() => this.props.checkBeforeMakeOwner(this.props, this.state.email)}
          > MAKE OWNER </Button>
          { this.props.modalConfig.type === INVITE_AND_MAKE_OWNER
            ? <Modal
              open={this.props.modalConfig.open}
              title={`User doesn't exist`}
              dividers={false}
              children={
                <p>We can still proceed ahead and transfer ownership to the
                new user and ask them to set a password to their account</p>
              }
              actions={
                <Button color='primary' onClickCapture={(e) => {
                  this.props.closeModal()
                  this.props.makeOwner({ type: 'other', email:this.state.email }, () => {
                    this.props.dispatch(reset('transferOwnership'))
                  })
                }}> INVITE & TRANSFER </Button>
              }
              closeModal={this.props.closeModal}
            />
            : this.props.modalConfig.type === OWNER_ALREADY_EXISTS
              ? <Modal
                open={this.props.modalConfig.open}
                title={`Action not possible`}
                dividers={false}
                children={
                  <p className='make-owner-message'> <span>{`${this.props.modalConfig.data.email}`}</span> is already the owner of <span>
                    {`${this.props.modalConfig.data.organization.name}`}.</span> Transfer ownership from <span>{`${this.props.modalConfig.data.email}`} </span>
                  before attempting to make them the owner of <span>{`${this.props.organization.name}`}.</span> </p>
                }
                actions={
                  <Button color='primary' onClickCapture={(e) => {
                    this.props.closeModal()
                  }}> OKAY </Button>
                }
                closeModal={this.props.closeModal}
              />
            : null
          }
        </form>
      </div>
    )
  }
}

TransferOwnership.propTypes = {
  email: PropTypes.string,
  modalConfig: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  makeOwner: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'transferOwnership',
  validate
})(TransferOwnership)
