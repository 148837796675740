import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'

const DownloadIcon = ({ ...props }) => (
  <IconButton className='icon-button' onClick={() => props.download()}>
    <i className='fa fa-download' />
  </IconButton>
)

DownloadIcon.propTypes = {
  download: PropTypes.func.isRequired
}

export default DownloadIcon
