const FormValidators = values => {
  const errors = {}
  const requiredFields = [ 'name', 'email', 'address', 'state', 'city', 'phoneNumber', 'zipCode', 'tagId',
    'Qr code', 'startTime' ]
  let val = values || {}

  // Error for required fields
  requiredFields.forEach(field => {
    if (!val[ field ]) {
      errors[ field ] = `${field} is required`
    }
  })

  // validate email
  if (val.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val.email)) {
    errors.email = 'Invalid email address'
  }

  // validate zipcode [src : https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex]
  if (val.zipCode && !/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i.test(val.zipCode)) {
    errors.zipCode = 'Invalid zipcode'
  }

  return errors
}

export default FormValidators
