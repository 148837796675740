import React from 'react'
import PropTypes from 'prop-types'
import _ from 'underscore'
import { browserHistory } from 'react-router'
import { Button } from '@material-ui/core'
import Modal from 'components/Modal'

const DeleteUsersConfirmModal = ({ ...props }) => {
  const { modalConfig, closeModal, deleteUser } = props
  const members = modalConfig.data.members
  const memberIds = _.pluck(members, 'id')
  return (
    <Modal
      title='Are you sure?'
      open={modalConfig.open}
      closeModal={closeModal}
      className='delete-user-confirmation'
      dividers={false}
      children={
        <p>Deleting user accounts is an irreversible process </p>
      }
      actions={<Button
        label={`Delete ${members.length} users`}
        color='primary'
        onClick={() => {
          closeModal()
          _.each(members, (member, index) => {
            deleteUser(member.id).then(() => {
              if (index === members.length - 1) {
                browserHistory.push(`/organizations/${props.organizationId}/info/show_address/`)
                props.removeMembersFromList(memberIds)
              }
            })
          })
        }}
      > {`DELETE ${members.length} USERS`} </Button>}
    />
  )
}

DeleteUsersConfirmModal.propTypes = {
  modalConfig: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
}

export default DeleteUsersConfirmModal
