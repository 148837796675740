import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { ADD_MEMBERS, PENDING_INVITATIONS } from 'constants/modalTypes'
import Modal from 'components/Modal'
import PendingInvitations from './PendingInvitations'
import JumpTo from 'components/JumpTo'
import DownloadIcon from 'components/DownloadIcon'

const MemberListHeader = ({ ...props }) => {
  return (
    <div className='member-page-header'>
      <div className='row fixed-page-header'>
        <div className='col-xs-12 page-header-section'>
          <div className='col-xs-8 col-sm-9 col-md-9 col-lg-9 header-section'>
            <div className='title-section'>
              <h2 className='title'>List of members in
                <span className='title-org-name'>
                  {props.organization.name}
                </span>
              </h2>
              <DownloadIcon
                download={() => props.organizationDetailsCsv({
                  organizationId: props.organization.id,
                  resource: 'organizationDetails'
                })
              }
              />
            </div>
          </div>
          <div className='col-xs-4 col-sm-3 col-md-3 col-lg-3 btn-section'>
            <Button
              variant='contained'
              color='primary'
              className='btn-blue'
              onClick={() => props.openModal(ADD_MEMBERS)}
            > ADD MEMBER </Button>
          </div>
        </div>
        <div className='col-xs-12 pending-jump-section'>
          <div className='col-xs-7'>
            <Button
              className='btn-flat-darkorange'
              onClick={() => props.openModal(PENDING_INVITATIONS)}
            > VIEW PENDING INVITATIONS </Button>
            {props.modalConfig.type === PENDING_INVITATIONS
            ? <Modal
              title='List of pending invitations'
              className='pending-invitations-modal'
              open={props.modalConfig.open}
              dividers
              closeModal={props.closeModal}
              children={<div className='modal-scroll no-archieve-modal'>
                {props.pendingInvitations.length
                ? <PendingInvitations
                  pendingInvitations={props.pendingInvitations}
                  organizationId={props.organization.id}
                  removeMember={props.removeMember}
                  removeMembersFromList={props.removeMembersFromList}
                  removeInvitationFromList={props.removeInvitationFromList}
                  resendInvite={props.resendInvite}
                  deleteInvitation={props.deleteInvitation}
                  />
                : <div className='no-archieve-message'>
                  <h3>There are no pending invitations</h3>
                </div>
              }
              </div>
              }
              />
              : null
            }
          </div>
          <div className='col-xs-5'>
            <JumpTo
              letters={props.letters}
              onClick={(target) => {
                document.getElementById('member-list').scrollTop = target.offsetTop
              }} />
          </div>
        </div>
      </div>
    </div>
  )
}

MemberListHeader.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalConfig: PropTypes.object.isRequired,
  organization: PropTypes.object,
  removeMember: PropTypes.func.isRequired,
  removeMembersFromList: PropTypes.func.isRequired,
  removeInvitationFromList: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  pendingInvitations: PropTypes.array,
  letters: PropTypes.array,
  organizationDetailsCsv: PropTypes.func.isRequired
}
export default MemberListHeader
