import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import _ from 'underscore'
import { auth } from 'utils/AuthService'
import { getFirstLetter } from 'utils/utils'
import { Divider, Drawer, Hidden, Grid, ListItem, ListItemText, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 256

const styles = (theme) => ({
  drawerPaper: {
    width: drawerWidth
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar
})

class Sidebar extends Component {
  isActiveRoute = (routeName) => {
    const { location } = this.props
    // found valid regex for UUID here: https://stackoverflow.com/a/13653180
    const routes = [{
      routeRegex: /\/?organizations\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/info\/*/,
      routeName: 'organizationInfo'
    }, {
      routeRegex: /\/?organizations\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/tags\/*/,
      routeName: 'tags'
    }, {
      routeRegex: /\/?organizations\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/aggregation_units\/*/,
      routeName: 'aggregationUnits'
    }, {
      routeRegex: /\/?organizations\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\/reports\/*/,
      routeName: 'reports'
    }]

    const currentRoute = _.findWhere(routes, { routeName: routeName })
    return currentRoute.routeRegex.test(location.pathname)
  }

  render () {
    const { user, location, organizationInfo, classes, openSidebar, handleSidebarOpen } = getDefaults(this.props)
    const selectedOrganizationId = this.props.params.orgId
    const selectedOrganization = organizationInfo ? organizationInfo.name : ''
    const disabled = (location.pathname === '/' ||
     location.pathname === '/requests/' || location.pathname === '/requests') ? 'disabled' : ''

    const organizationWarn = (
      <div className='menu-item active-info'>
        <ListItem button disableRipple component={Link} to='/' className={location.pathname === '/' ? 'active' : ''}>
          <span className='material-icons-outlined menuitem-icon'>
            <Icon className='fa fa-info-circle icon' />
          </span>
          <ListItemText className='sidebar-text' primary='Manage Organizations' />
        </ListItem>
      </div>
    )
    const selectedOrganizationName = (
      <div className='menu-item active-info'>
        <ListItem button disableRipple component={Link} to='/' className='active'>
          <span className='material-icons-outlined menuitem-icon'>
            <Icon className='fa fa-info-circle icon' />
          </span>
          <ListItemText className='sidebar-text' primary='Manage Organizations' secondary={selectedOrganization} />
        </ListItem>
      </div>
    )

    const organizationActions = (
      <div>
        {selectedOrganizationName}
        <div className='menu-item org-submenu'>
          <ListItem
            button
            disableRipple
            component={Link}
            to={`/organizations/${selectedOrganizationId}/info/show_address/`}
            className={`${disabled} ${this.isActiveRoute('organizationInfo') ? 'active' : ''}`}
          >
            <span className='material-icons-outlined menuitem-icon'>
              <Icon className='fa fa-briefcase icon' />
            </span>
            <ListItemText primary='Organization info' />
          </ListItem>
        </div>
        <div className='menu-item org-submenu'>
          <ListItem button disableRipple component={Link} to={'/organizations/' + selectedOrganizationId + '/tags/'}
            className={`${disabled} ${this.isActiveRoute('tags') ? 'active' : ''}`}>
            <span className='material-icons-outlined menuitem-icon'>
              <Icon className='fa fa-tags icon' />
            </span>
            <ListItemText primary='Tags' />
          </ListItem>
        </div>
        <div className='menu-item org-submenu'>
          <ListItem
            button
            disableRipple
            component={Link}
            to={'/organizations/' + selectedOrganizationId + '/aggregation_units/'}
            className={`${disabled} ${this.isActiveRoute('aggregationUnits') ? 'active' : ''}`}
          >
            <span className='material-icons-outlined menuitem-icon'>
              <Icon className='fa fa-cubes icon' />
            </span>
            <ListItemText primary='Aggregation units' />
          </ListItem>
        </div>
        <div className='menu-item org-submenu'>
          <ListItem button disableRipple component={Link} to={'/organizations/' + selectedOrganizationId + '/reports/'}
            className={`${disabled} ${this.isActiveRoute('reports') ? 'active' : ''}`}>
            <span className='material-icons-outlined menuitem-icon'>
              <Icon className='fa fa-file-text icon' />
            </span>
            <ListItemText primary='Reports' />
          </ListItem>
        </div>
      </div>
    )

    const drawer = (
      <div className='sidebar'>
        <Grid className='user-view'>
          <div className='circle'>
            <span>{getFirstLetter(user.fullName)}</span>
          </div>
          <h3> {user.fullName} </h3>
          <p> {user.organization.name} </p>
        </Grid>
        <Divider style={{ background: 'white' }} />
        <div className='menu-item active-info'>
          <ListItem button disableRipple component={Link} to='/requests/'
            className={(location.pathname === '/requests/' || location.pathname === '/requests') ? 'active' : ''}>
            <span className='material-icons-outlined menuitem-icon'>
              <Icon className='fa fa-eye icon' />
            </span>
            <ListItemText className='sidebar-text' primary='View Requests' />
          </ListItem>
        </div>
        {
          location.pathname === '/' || location.pathname === '/requests/' || location.pathname === '/requests'
          ? organizationWarn
          : organizationActions
        }
      </div>
    )

    return (
      <nav className={classes.drawer} aria-label='mailbox folders'>
        <Hidden mdUp implementation='css'>
          <Drawer
            className='side-nav'
            variant='temporary'
            anchor='left'
            open={openSidebar}
            onClose={handleSidebarOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {/* <div className={classes.toolbar} /> */}
            {drawer}
            <div className='logout-menu'>
              <ListItem button disableRipple component='a' name='Logout' onClick={event => { auth.logout() }}>
                <span className='material-icons-outlined menuitem-icon'>
                  <Icon className='fa fa-power-off icon' />
                </span>
                <ListItemText primary='Logout' />
              </ListItem>
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            className='side-nav'
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            <div className={classes.toolbar} />
            {drawer}
            <div className='logout-menu'>
              <ListItem button disableRipple component='a' name='Logout' onClick={event => { auth.logout() }}>
                <span className='material-icons-outlined menuitem-icon'>
                  <Icon className='fa fa-power-off icon' />
                </span>
                <ListItemText primary='Logout' />
              </ListItem>
            </div>
          </Drawer>
        </Hidden>
      </nav>
    )
  }
}

Sidebar.propTypes = {
  sidebarToggle: PropTypes.func.isRequired,
  params: PropTypes.object,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleSidebarOpen: PropTypes.func.isRequired,
  openSidebar: PropTypes.bool.isRequired
}

Sidebar.defaultProps = {
  user: {
    organization: {
      name: ''
    }
  }
}

const getDefaults = (props) => {
  const defaultData = {}
  if (_.isEmpty(props.user)) {
    defaultData.user = {
      fullName: '',
      organization: {
        name: ''
      }
    }
  }
  if (_.isEmpty(props.organizationInfo)) {
    defaultData.organizationInfo = {
      name: ''
    }
  }

  return Object.assign({}, props, defaultData)
}

export default withStyles(styles)(Sidebar)
