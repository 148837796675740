import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import _ from 'underscore'
import { List, ListItem, ListItemText, ListItemAvatar,
  ListItemSecondaryAction, Avatar, Button, Checkbox } from '@material-ui/core'
import MemberListHeader from './MemberListHeader'
import FloatingAddButton from 'components/FloatingAddButton'
import { MAKE_MAMBER_AS_OWNER } from 'constants/modalTypes'
import { getFirstLetter, groupByName } from 'utils/utils'

const MemberList = ({ ...props }) => {
  let { members, id, selectedMembers, owner, invitations } = getDefaults(props.organization)
  selectedMembers = selectedMembers || []
  let separatorRefs = []
  owner = owner || {}

  let acceptedMembers = _.filter(members, member => !member.isArchived)
  if (owner) {
    acceptedMembers.push(owner)
  }
  const groupByMemberName = groupByName(acceptedMembers)

  const pendingInvitations = _.filter(invitations.pending, invitation => invitation.isValid && !invitation.isInvitationAccepted)

  const checkbox = (member, checked) => (
    <Checkbox className='checkbox'
      onClick={(e) => {
        e.stopPropagation()
        props.selectMembers(member)
      }}
      checked={checked}
    />
  )

  const leftAvatar = (member) => (
    <Avatar
      style={{ color: 'white', backgroundColor: '#455A64' }}
      className='style-avatar'
      onClick={(e) => {
        e.stopPropagation()
        props.selectMembers(member)
      }}
    >
      {member.name
        ? getFirstLetter(member.name)
        : null
      }
    </Avatar>
  )

  return (
    <div className='middle-section'>
      <div className='organization-info-page'>
        <MemberListHeader
          openModal={props.openModal}
          closeModal={props.closeModal}
          modalConfig={props.modalConfig}
          organization={props.organization}
          removeMember={props.removeMember}
          removeMembersFromList={props.removeMembersFromList}
          removeInvitationFromList={props.removeInvitationFromList}
          addMember={props.addMember}
          resendInvite={props.resendInvite}
          deleteInvitation={props.deleteInvitation}
          pendingInvitations={pendingInvitations}
          organizationDetailsCsv={props.organizationDetailsCsv}
          letters={_.keys(groupByMemberName)}
          separatorRefs={separatorRefs}
        />
        <div className='member-scroll page-scroll' id='member-list'>
          <div className='row member-list-section'>
            <div className='col-xs-12'>
              {_.map(groupByMemberName, (members, firstLetter) => {
                return (
                  <div key={firstLetter}>
                    <div className='separator'>
                      <span id={firstLetter}>{firstLetter.toUpperCase()}</span>
                    </div>
                    <List className='list'>
                      {
                        members.map((member, index) => {
                          const checked = _.contains(selectedMembers, member)
                          return (<div>
                            <ListItem className='list-item' key={member.name + index} onClick={() =>
                              (browserHistory.push(`/organizations/${id}/info/edit_member/${member.id}`))}>
                              <ListItemAvatar>
                                {
                                  !selectedMembers.length
                                    ? leftAvatar(member)
                                    : checkbox(member, checked)
                                }
                              </ListItemAvatar>
                              <ListItemText
                                primary={<div className='primary-text'>
                                  {member.name}
                                  {member.role === 'owner'
                                    ? <span className='owner-label'>(owner)</span>
                                    : null
                                    }
                                  {member.role === 'bizdev'
                                    ? <span className='owner-label'>(bizdev)</span>
                                    : null
                                    }
                                </div>}
                                secondary={<span className='secondary-text'>{member.email}</span>}
                              />
                              {(!selectedMembers.length && member.role !== 'owner')
                              ? <ListItemSecondaryAction>
                                <Button
                                  className='btn-makeowner'
                                  variant='contained'
                                  color='primary'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    props.openModal(MAKE_MAMBER_AS_OWNER, member)
                                  }}
                                > MAKE OWNER </Button>
                              </ListItemSecondaryAction>
                              : null}
                            </ListItem>
                          </div>)
                        })
                      }
                    </List>
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
        <FloatingAddButton onClick={() => props.openModal('ADD_MEMBERS')} />
      </div>
    </div>
  )
}

MemberList.propTypes = {
  organization: PropTypes.object,
  selectMembers: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  removeMembersFromList: PropTypes.func.isRequired,
  removeInvitationFromList: PropTypes.func.isRequired,
  modalConfig: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  addMember: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  organizationDetailsCsv: PropTypes.func.isRequired
}

const getDefaults = (organization) => {
  if (_.isEmpty(organization)) {
    return Object.assign({}, organization, { name: '', members: [], invitations: { pending: [] } })
  } else {
    return organization
  }
}

export default MemberList
