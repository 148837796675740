const initialState = false

export default function loaderReducer (state = initialState, action) {
  const actionType = action.type
  let pendingAction = actionType.match(/.*_PENDING/)
  pendingAction = pendingAction ? pendingAction[0] : ''

  let successAction = actionType.match(/.*_FULFILLED/)
  successAction = successAction ? successAction[0] : ''

  let rejectedAction = actionType.match(/.*_REJECTED/)
  rejectedAction = rejectedAction ? rejectedAction[0] : ''

  switch (action.type) {
    case pendingAction:
      return true
    case successAction:
    case rejectedAction:
      return false
    default:
      break
  }
  return state
}
