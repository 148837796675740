import S from 'string'
import _ from 'underscore'
import axios from 'axios'
import { showLoader } from 'store/loader'

export function convertToCamelCase (object) {
  let convertedData = {}
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (_.isObject(object[key]) && !_.isArray(object[key])) {
        convertedData[S(key).camelize().s] = convertToCamelCase(object[key])
      } else if (_.isArray(object[key])) {
        convertedData[S(key).camelize().s] = object[key].map((obj) => {
          if (typeof obj === 'string') {
            return obj
          } else {
            return convertToCamelCase(obj)
          }
        })
      } else {
        convertedData[S(key).camelize().s] = object[key]
      }
    }
  }
  return convertedData
}

export function convertToSnakeCase (object) {
  let convertedData = {}
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (_.isObject(object[key]) && !_.isArray(object[key])) {
        convertedData[S(key).underscore().s] = convertToSnakeCase(object[key])
      } else if (_.isArray(object[key])) {
        convertedData[S(key).underscore().s] = object[key].map((obj) => {
          if (typeof obj === 'string') {
            return obj
          } else {
            return convertToSnakeCase(obj)
          }
        })
      } else {
        convertedData[S(key).underscore().s] = object[key]
      }
    }
  }
  return convertedData
}

export function convertToTitleCase (str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function getLatLong (address, resolve) {
  const fullAddress = _.values(address).join(',')
  let latLong = {
    latitude: '',
    longitude: ''
  }

  var geocoder = new window.google.maps.Geocoder()
  geocoder.geocode({ 'address': fullAddress }, function (results, status) {
    if (status === window.google.maps.GeocoderStatus.OK) {
      latLong.latitude = results[0].geometry.location.lat()
      latLong.longitude = results[0].geometry.location.lng()
      resolve(latLong)
    }
  })
}

export const getFirstLetter = (string) => {
  return S(string).left(1).s.toUpperCase()
}

export const downloadFileFromGetResponse = ({ data, resource }) => {
  var str = data

  var anchor = document.createElement('a')
  var windowUrl = window.URL || window.webkitURL
  if (str.length > 1048576 && typeof windowUrl.createObjectURL === 'function') {
    var blob = new Blob([data], { type: 'text/csv' })
    var url = windowUrl.createObjectURL(blob)
    anchor.prop('href', url)
    anchor.prop('download', resource + '.csv')
    anchor.get(0).click()
    windowUrl.revokeObjectURL(url)
  } else {
    anchor.setAttribute('href', 'data:text/plain;base64,' + window.btoa(str))
    anchor.setAttribute('download', resource + '.csv')
    anchor.click()
  }
}

export const groupByName = (array) => {
  const sortedArry = _.sortBy(array, object => {
    return object.name ? object.name.toLowerCase() : ''
  })
  const groupByName = _.groupBy(sortedArry, object => {
    return object.name ? object.name[0].toLowerCase() : ''
  })

  return groupByName
}

export function interceptorBeforeAndAfterAxiosCall (store) {
  axios.interceptors.request.use((config) => {
    // let load = store.getState('loader')
    store.dispatch(showLoader(true))
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  axios.interceptors.response.use((response) => {
    store.dispatch(showLoader(false))
    return response
  }, (error) => {
    store.dispatch(showLoader(false))
    return Promise.reject(error)
  })
}
