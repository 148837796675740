import React from 'react'
import PropTypes from 'prop-types'

const ActionBar = ({ ...props }) => {
  const { selectedItems, backButton } = props
  return (
    <div className='action-bar'>
      <span className='back-button'>
        {
          backButton !== undefined
          ? backButton
          : null
        }
      </span>
      <h3> {selectedItems.length} selected</h3>
      <div className='action-button'>
        {props.children}
      </div>

    </div>
  )
}

ActionBar.propTypes = {
  selectedItems: PropTypes.array,
  backButton: PropTypes.element,
  children: PropTypes.element
}

export default ActionBar
