import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router'
import { Button, Divider, TextField } from '@material-ui/core'
import TimeZoneSelector from 'components/TimezoneSelector'
import validate from 'validators/FormValidators'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/libphonenumber.js'

class OrganizationAddressEdit extends Component {
  constructor (props) {
    super(props)
    const { organization } = this.props
    const { phoneNumber, name, address, timezone } = organization
    const { line1, line2, country, state, zipCode, city } = address
    this.state = {
      name: name,
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      zipCode: zipCode,
      country: country,
      timezoneName: timezone,
      phoneNumber: phoneNumber,
      phoneDialCode: this.setPhoneDialCode(phoneNumber),
      countryDialCode: this.setCountryDialCode(country)
    }
    this.zipCodePattern = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i
    this.phonePattern = /^([0-9\-+\s()]+){10,}$/
  }
  updateFields = (fieldName, fieldValue) => {
    var newState = {}
    newState[fieldName] = fieldValue
    this.setState(newState)
  }
  setPhoneDialCode = (number) => {
    let phoneDialCode = null
    if (number) {
      phoneDialCode = number.split('-')[0]
      phoneDialCode = phoneDialCode.split('+')[1]
    }
    return phoneDialCode
  }
  setCountryDialCode = (country) => {
    let countryDialCode = null
    if (country) {
      countryDialCode = country.split('-')[0]
      countryDialCode = countryDialCode.split('+')[1]
    }
    return countryDialCode
  }
  onChangeCountryInputFlag = (status, value, data, even) => {
    this.setState({ country: value.name })
  }
  onChangePhoneInputFlag = (status, value, data, even, bb) => {
    this.setState({ phoneDialCode: value.dialCode })
  }
  onChangePhoneNumber = (status, value, data, even, bb) => {
    const regex = new RegExp('^' + data.dialCode + '+', 'i')
    const processedValue = value && (value[0] === '+' ? value.substr(1).replace(regex, '') : value)
    this.setState({ phoneNumber:  processedValue })
  }
  onChangeTimezone = (timezone) => {
    this.setState({ timezoneName:  timezone && timezone.value })
  }
  updateAddress = (e) => {
    e.preventDefault()

    const { country, phoneNumber, timezoneName, phoneDialCode } = this.state
    const phone = `+${phoneDialCode}-${phoneNumber}`
    this.props.editOrganizationAddress(country, timezoneName, phone)
  }
  handleSaveButton () {
    const { organization } = this.props
    const { phoneNumber, name, address, timezone } = organization
    const { line1, line2, country, city, zipCode, state } = address
    let pNumber = this.state.phoneNumber
    let cName = '+' + this.state.countryDialCode + '-' + this.state.country
    if (this.state.phoneNumber) {
      pNumber = `+` + this.state.phoneDialCode + `-` + pNumber.replace(/[^\d]/g, '')
    }
    return (
      this.state.name === name &&
      this.state.line1 === line1 &&
      this.state.line2 === line2 &&
      this.state.city === city &&
      this.state.state === state &&
      this.state.zipCode === zipCode &&
      this.state.timezoneName === timezone &&
      (cName === country || this.state.country === country) &&
      (pNumber === phoneNumber || this.state.phoneNumber === phoneNumber) ||
      !this.state.name || !this.state.line1 || !this.state.city || !this.state.state || !this.state.zipCode ||
      !this.state.phoneNumber ||
      !(this.zipCodePattern.test(this.state.zipCode) && this.phonePattern.test(this.state.phoneNumber))
    )
  }
  renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      helperText={touched && error}
      error={touched && error}
      {...input}
      {...custom}
    />
  )
  render () {
    const { country, timezoneName, phoneNumber } = this.state
    return (
      <div className='edit-address-section'>
        <h2> Organization Info </h2>
        <Divider />
        <form>
          <Field
            className='input-field'
            name='name'
            component={this.renderTextField}
            label='Organization name'
            onChange={(event) => this.updateFields('name', event.target.value)}
          />
          <Field
            className='input-field'
            name='line1'
            component={this.renderTextField}
            label='Address'
            onChange={(event) => this.updateFields('line1', event.target.value)}
          />
          <Field
            className='input-field'
            name='line2'
            component={this.renderTextField}
            onChange={(event) => this.updateFields('line2', event.target.value)}
          />
          <p className='input-label'>Country</p>
          <IntlTelInput
            css={['intl-tel-input country-dropdown', 'form-control input-md']}
            utilsScript={'libphonenumber.js'}
            autoPlaceholder={false}
            onSelectFlag={this.onChangeCountryInputFlag}
            value={country}
          />
          <Field
            className='input-field'
            name='state'
            component={this.renderTextField}
            label='State'
            onChange={(event) => this.updateFields('state', event.target.value)}
          />
          <Field
            className='input-field'
            name='city'
            component={this.renderTextField}
            label='Town / City'
            onChange={(event) => this.updateFields('city', event.target.value)}
          />
          <Field
            className='input-field'
            name='zipCode'
            component={this.renderTextField}
            label='Zip code'
            onChange={(event) => this.updateFields('zipCode', event.target.value)}
          />
          <p className='input-label'>Phone</p>
          <IntlTelInput
            css={['intl-tel-input', 'form-control input-md']}
            utilsScript={'libphonenumber.js'}
            autoPlaceholder
            onSelectFlag={this.onChangePhoneInputFlag}
            onPhoneNumberChange={this.onChangePhoneNumber}
            separateDialCode
            value={phoneNumber}
          />
          { !this.phonePattern.test(phoneNumber) || !phoneNumber
           ? <span className='phoneerror'>Please enter {phoneNumber ? 'valid' : null} phonenumber</span>
           : null
          }
          <p className='input-label'>Time Zone</p>
          <TimeZoneSelector
            className='input-field'
            timezone={timezoneName}
            onChange={this.onChangeTimezone}
          />
          <div className='button-placeholder'>
            <Link to={`/organizations/${this.props.organization.id}/info/show_address/`}>
              <Button className='btn-darkyellow' variant='contained' color='primary'> CANCEL </Button>
            </Link>
            <Button
              className='btn-darkcyan'
              variant='contained'
              color='primary'
              disabled={this.handleSaveButton()}
              onClick={this.updateAddress}
            > UPDATE </Button>
          </div>
        </form>
      </div>
    )
  }
}

OrganizationAddressEdit.propTypes = {
  organization: PropTypes.object,
  editOrganizationAddress: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'organizationForm',
  validate
})(OrganizationAddressEdit)
