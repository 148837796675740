import { SIDEBAR_TOGGLE } from 'constants/actionTypes'

// ------------------------------------
// Actions
// ------------------------------------
export function sidebarToggleAction (sidebar) {
  return {
    type    : SIDEBAR_TOGGLE,
    payload : !sidebar
  }
}

export const sidebarToggle = () => {
  return (dispatch, getState) => {
    dispatch(sidebarToggleAction(getState().sidebar))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
var initialState
var mq = window.matchMedia('(min-width: 1024px)')
var mq2 = window.matchMedia('(max-width: 1023px)')

if (mq.matches) {
  initialState = true
}
if (mq2.matches) {
  initialState = false
}

export default function sidebarReducer (state = initialState, action) {
  return action.type === SIDEBAR_TOGGLE
    ? action.payload
    : state
}
